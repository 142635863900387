import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useNavigate, useLocation } from 'react-router-dom';
import '../css/Learninghub.css';

export const Learninghub = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedTag, setSelectedTag] = useState('');
    const [posts, setPosts] = useState([]);
    const [tags, setTags] = useState([]);
    const [postsPerPage, setPostsPerPage] = useState(5); // 초기 페이지당 표시할 게시물 수
    const navigate = useNavigate();
    const location = useLocation();

    // URL에서 현재 페이지 번호 추출
    const currentPageFromURL = parseInt(new URLSearchParams(location.search).get('page')) || 1;
    const [currentPage, setCurrentPage] = useState(currentPageFromURL);

    // 반응형 페이지네이션
    useEffect(() => {
        const updatePostsPerPage = () => {
            const width = window.innerWidth;
            if (width > 1024) {
                setPostsPerPage(10); // 넓은 화면에서는 10개
            } else if (width > 768) {
                setPostsPerPage(7); // 중간 화면에서는 7개
            } else {
                setPostsPerPage(5); // 더 작은 화면에서는 5개
            }
        };

        updatePostsPerPage();
        window.addEventListener('resize', updatePostsPerPage);
        return () => window.removeEventListener('resize', updatePostsPerPage);
    }, []);

    // Firestore에서 포스트 데이터 가져오기
    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "posts"));
                const postData = querySnapshot.docs.map(doc => {
                    const data = doc.data();
                    const date = data.date ? data.date.toDate() : new Date();
                    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;

                    return {
                        id: doc.id,
                        title: data.title,
                        content: data.content,
                        tags: data.tags,
                        date: formattedDate,
                        timestamp: date // 정렬용
                    };
                });

                postData.sort((a, b) => b.timestamp - a.timestamp);
                setPosts(postData);
            } catch (error) {
                console.error("Error fetching posts:", error);
            }
        };

        fetchPosts();
    }, []);

    useEffect(() => {
        const fetchTags = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "tags"));
                const tagData = querySnapshot.docs.map(doc => doc.data().name);
                setTags(tagData);
            } catch (error) {
                console.error("Error fetching tags:", error);
            }
        };

        fetchTags();
    }, []);

    const filteredPosts = posts.filter((post) => {
        const matchesSearch = post.title.includes(searchTerm) || post.content.includes(searchTerm);
        const matchesTag = selectedTag ? post.tags.includes(selectedTag) : true;
        return matchesSearch && matchesTag;
    });

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);
    const totalPages = Math.ceil(filteredPosts.length / postsPerPage);

    const goToPreviousPage = () => {
        if (currentPage > 1) {
            const newPage = currentPage - 1;
            setCurrentPage(newPage);
            navigate(`?page=${newPage}`);
        }
    };

    const goToNextPage = () => {
        if (currentPage < totalPages) {
            const newPage = currentPage + 1;
            setCurrentPage(newPage);
            navigate(`?page=${newPage}`);
        }
    };

    const handlePageInputChange = (e) => {
        const pageNumber = parseInt(e.target.value, 10);
        if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
            navigate(`?page=${pageNumber}`);
        }
    };

    const handleSearch = (e) => setSearchTerm(e.target.value);
    const handleTagClick = (tag) => setSelectedTag(tag === selectedTag ? '' : tag);
    const handleTitleClick = (postId) => navigate(`/learninghub/posts/${postId}`);
    const handleCreatePost = async () => {
        const enteredPassword = prompt("문서를 작성하려면 비밀번호를 입력하세요:");
        if (!enteredPassword) return;

        try {
            const docRef = doc(db, "settings", "password");
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const firebasePassword = docSnap.data().password;
                if (enteredPassword === firebasePassword) {
                    navigate('/learninghub/create'); // 비밀번호가 일치할 때 작성 페이지로 이동
                } else {
                    alert("비밀번호가 틀렸습니다.");
                }
            } else {
                alert("비밀번호 정보를 가져올 수 없습니다.");
            }
        } catch (error) {
            alert("비밀번호 확인 중 오류가 발생했습니다.");
        }
    };

    const handleAddTag = async () => {
        const enteredPassword = prompt("태그를 추가하려면 비밀번호를 입력하세요:");
        if (!enteredPassword) return;

        try {
            const docRef = doc(db, "settings", "password");
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const firebasePassword = docSnap.data().password;
                if (enteredPassword === firebasePassword) {
                    const newTag = prompt("추가할 태그 이름을 입력하세요:");
                    if (!newTag) return;

                    await addDoc(collection(db, "tags"), { name: newTag });
                    setTags(prevTags => [...prevTags, newTag]);
                    alert("태그가 추가되었습니다.");
                } else {
                    alert("비밀번호가 틀렸습니다.");
                }
            } else {
                alert("비밀번호 정보를 가져올 수 없습니다.");
            }
        } catch (error) {
            alert("비밀번호 확인 중 오류가 발생했습니다.");
        }
    };

    return (
        <div className='noScroll'>
            <div className='Learninghub_Container'>
                <h1 className='Title'>러닝허브</h1>
                <div className='LH_Wrap'>
                    <div className='search-and-tags'>
                        <div className='search'>
                            <input
                                type="text"
                                placeholder="검색..."
                                value={searchTerm}
                                onChange={handleSearch}
                            />
                            <span className="material-symbols-outlined icon">search</span>
                        </div>
                        <div className='tags scrollable'>
                            <h3>Tags</h3>
                            {tags.map((tag) => (
                                <button
                                    key={tag}
                                    onClick={() => handleTagClick(tag)}
                                    className={selectedTag === tag ? 'selected' : ''}
                                >
                                    {tag}
                                </button>
                            ))}
                            <button onClick={handleAddTag} className="add-tag-button">+</button>
                        </div>
                    </div>
                    <div className='LHcontentsWrap'>
                        <div className='LHcontents'>
                            {currentPosts.length > 0 ? (
                                currentPosts.map((post) => (
                                    <div key={post.id} className="LHpost" onClick={() => handleTitleClick(post.id)}>
                                        <h4>{post.title}</h4>
                                        <p>{post.date}</p>
                                    </div>
                                ))
                            ) : (
                                <div>검색 결과가 없습니다.</div>
                            )}
                        </div>
                        <div className="pagination">
                            <button className="material-symbols-outlined" onClick={goToPreviousPage} disabled={currentPage === 1}>arrow_back_ios_new</button>
                            <div className="page-input">
                                <input
                                    min="1"
                                    max={totalPages}
                                    value={currentPage}
                                    onChange={handlePageInputChange}
                                    placeholder="페이지 입력"
                                />
                            </div>
                            <button className="material-symbols-outlined" onClick={goToNextPage} disabled={currentPage === totalPages}>arrow_forward_ios</button>
                            <span>/{totalPages}</span>
                        </div>

                        <button onClick={handleCreatePost} className="create-btn">
                            작성
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};