import React, { useState, useEffect } from 'react';
import '../css/Weather.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSun,
    faCloudSun,
    faCloud,
    faCloudShowersHeavy,
    faBolt,
    faSnowflake,
    faSmog,
    faMoon,
    faCloudMoon,
    faCloudMeatball,
    faCloudSunRain,
    faCloudMoonRain
} from '@fortawesome/free-solid-svg-icons';
import { MapContainer, TileLayer, useMapEvents, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Weather_API_KEY } from '../../firebase';
import L from 'leaflet';

const getKoreanDayOfWeek = (dateStr) => {
    const daysInKorean = ['일', '월', '화', '수', '목', '금', '토'];
    const date = new Date(dateStr);
    return daysInKorean[date.getDay()];
};

const simpleMarkerIcon = new L.DivIcon({
    className: 'custom-div-icon',
    html: `<div style="
        width: 15px;
        height: 15px;
        background-color: #0078ff;
        border-radius: 50%;
        border: 2px solid white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    "></div>`,
    iconSize: [15, 15],
    iconAnchor: [7.5, 7.5],
    popupAnchor: [0, -10],
});

const southKoreaBounds = [
    [33.0, 124.0],
    [39.5, 131.0],
];

const mapWeatherToIconAndClass = (iconCode) => {
    switch (iconCode) {
        case '01d': return { icon: faSun, className: 'icon-clear-sky-day' };
        case '01n': return { icon: faMoon, className: 'icon-clear-sky-night' };
        case '02d': return { icon: faCloudSun, className: 'icon-few-clouds-day' };
        case '02n': return { icon: faCloudMoon, className: 'icon-few-clouds-night' };
        case '03d':
        case '03n': return { icon: faCloud, className: 'icon-scattered-clouds' };
        case '04d':
        case '04n': return { icon: faCloudMeatball, className: 'icon-broken-clouds' };
        case '09d':
        case '09n': return { icon: faCloudShowersHeavy, className: 'icon-shower-rain' };
        case '10d': return { icon: faCloudSunRain, className: 'icon-rain-day' };
        case '10n': return { icon: faCloudMoonRain, className: 'icon-rain-night' };
        case '11d':
        case '11n': return { icon: faBolt, className: 'icon-thunderstorm' };
        case '13d':
        case '13n': return { icon: faSnowflake, className: 'icon-snow' };
        case '50d':
        case '50n': return { icon: faSmog, className: 'icon-mist' };
        default: return { icon: faSun, className: 'icon-clear-sky-day' };
    }
};

export const Weather = () => {
    const [forecast, setForecast] = useState([]);
    const [location, setLocation] = useState({ lat: 37.5665, lng: 126.9780 });
    const [cityName, setCityName] = useState('서울특별시');
    const [showModal, setShowModal] = useState(false);
    const [selectedWeather, setSelectedWeather] = useState(null);

    const MapClickHandler = () => {
        useMapEvents({
            click(e) {
                const { lat, lng } = e.latlng;
                setLocation({ lat, lng });
            },
        });
        return null;
    };

    useEffect(() => {
        const fetchWeatherData = async (latitude, longitude) => {
            try {
                const response = await fetch(
                    `https://api.openweathermap.org/data/2.5/forecast?lat=${latitude}&lon=${longitude}&appid=${Weather_API_KEY}&units=metric&lang=kr`
                );
                const data = await response.json();
                setForecast(data.list || []);
                fetchCityName(latitude, longitude);
            } catch (error) {
                console.error('데이터 에러', error);
            }
        };

        const fetchCityName = async (latitude, longitude) => {
            try {
                const response = await fetch(
                    `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&accept-language=ko`
                );
                const data = await response.json();
                setCityName(
                    data.address.city ||
                    data.address.town ||
                    data.address.village ||
                    data.address.state ||
                    '알 수 없음'
                );
            } catch (error) {
                console.error('지명 가져오기 에러', error);
                setCityName('알 수 없음');
            }
        };

        fetchWeatherData(location.lat, location.lng);
    }, [location]);

    const handleEntryClick = (dayWeather) => {
        setSelectedWeather(dayWeather);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setLocation({ lat: latitude, lng: longitude });
                },
                () => {
                    alert('현재 위치를 가져올 수 없습니다.');
                }
            );
        } else {
            alert('현재 위치를 지원하지 않는 브라우저입니다.');
        }
    };

    const groupedForecast = forecast.reduce((acc, entry) => {
        const date = entry.dt_txt.split(' ')[0];
        if (!acc[date]) acc[date] = [];
        acc[date].push(entry);
        return acc;
    }, {});

    const dailyForecasts = Object.keys(groupedForecast).map((date) => {
        const dayEntries = groupedForecast[date];
        const temps = dayEntries.map((entry) => entry.main.temp);
        const uniqueDescriptions = [...new Set(dayEntries.map((entry) => entry.weather[0].description))];
        const weatherIcon = dayEntries[0].weather[0].icon;

        return {
            date,
            minTemp: Math.min(...temps),
            maxTemp: Math.max(...temps),
            description: uniqueDescriptions.join(', '),
            icon: weatherIcon,
            entries: dayEntries,
        };
    });

    return (
        <div className='noScroll'>
            <div className="Weather-Wrap">
                <h1 className="Title">날씨정보페이지</h1>
             
                <MapContainer
                    center={[location.lat, location.lng]}
                    zoom={7}
                    style={{ height: '400px', width: '80%' }}
                    maxBounds={southKoreaBounds}
                    bounds={southKoreaBounds}
                    scrollWheelZoom={false}
                >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <MapClickHandler />
                    <Marker
                        position={[location.lat, location.lng]}
                        icon={simpleMarkerIcon}
                    />
                </MapContainer>
                <div className="location-info">선택된 위치 : {cityName}   <button onClick={handleCurrentLocation} className="current-location-btn">
                <span class="material-symbols-outlined">
pin_drop
</span>
                </button></div>
                <div className='forecast-row'>
                    {forecast.slice(0, 8).map((entry, index) => {
                        const { icon, className } = mapWeatherToIconAndClass(entry.weather[0].icon);
                        const time = entry.dt_txt.split(" ")[1].slice(0, 5);
                        return (
                            <div key={index} className="forecast-entry">
                                <div className='forecast_text'>시간 : {time}</div>
                                <div className='forecast_text'>온도 : {entry.main.temp}°C</div>
                                <div className='forecast_text'>{entry.weather[0].description}</div>
                                <FontAwesomeIcon icon={icon} className={className} />
                            </div>
                        );
                    })}
                </div>
                <div className='forecast-col'>
                    {dailyForecasts.map((day, index) => {
                        const { icon, className } = mapWeatherToIconAndClass(day.icon);
                        return (
                            <div key={index} className="forecast-entry" onClick={() => handleEntryClick(day.entries)}>
                                <div className='forecast_text'>{getKoreanDayOfWeek(day.date)}</div>
                                <FontAwesomeIcon icon={icon} className={className} />
                                <div className='forecast_text'>최저 : {day.minTemp.toFixed(1)}°C</div>
                                <div className='forecast_text'>최고 : {day.maxTemp.toFixed(1)}°C</div>
                            </div>
                        );
                    })}
                </div>
                {showModal && selectedWeather && (
                    <div id="modal-background" className="modal" onClick={handleCloseModal}>
                        <div className="modal-content">
                            <h2>날씨 상세 정보</h2>
                            {selectedWeather.map((entry, idx) => {
                                const { icon, className } = mapWeatherToIconAndClass(entry.weather[0].icon);
                                const time = entry.dt_txt.split(" ")[1].slice(0, 5);
                                return (
                                    <div key={idx} className='modal-item'>
                                        <div>시간 : {time}</div>
                                        <div>온도 : {entry.main.temp}°C</div>
                                        <div>날씨 : {entry.weather[0].description}</div>
                                        <FontAwesomeIcon icon={icon} className={className} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};